
	import http from "../../../../common/services/http";
	import { computed, defineComponent, ref, reactive, onMounted } from "vue";
	import { format } from "date-fns";

	export default defineComponent({
		setup(props) {
			const search = ref("");
			const headers = {
				count: "Pack Count",
				weight: "Pack Weight",
			};

			const products = ref([]);
			const costs = ref([]);

			const creating = ref(false);

			const customers = ref([]);
			const user_id = ref(0)

			const states = ref([
				{ value: "select", label: "Select" },
				{ value: "loose", label: "Loose" },
				{ value: "prepack", label: "Prepack" },
			]);

			const record = reactive({
				user_id: 0,
				product_id: 0,
				box: 0,
				primary: 0,
				count: 0,
				weight: 0,
				shilling: 0,
				pound: 0,
				dollar: 0,
				name: "",
				active: true,
			});

			const product = computed(() => {
				return (
					products.value.find(
						(p: any) => p.id == record.product_id
					) || {
						name: "",
					}
				);
			});

			const box = computed(() => {
				return (
					costs.value.find((c: any) => c.id == record.box) || {
						name: "",
					}
				);
			});

			const primary = computed(() => {
				return costs.value.find((c: any) => c.id == record.primary);
			});

			const selected = ref([]);

			const processing = ref(false);
			const created = ref(false);
			const updated = ref(false);

			const customer = computed(() => {
				return (
					customers.value?.find(
						(c: any) => c.id == record.user_id
					) || {
						name: "",
					}
				);
			});

			const assignments = ref({
				data: [],
			});

			function fD(d: any) {
				return format(new Date(d), "EEE MMM do, yyyy");
			}

			function setName() {
				record.name = `${product?.value?.name} ${record.count}x${record.weight}g`;
			}

			function formatName(record: any): string {
				const n = `${record.product?.name} ${record.count}x${record.weight}g`;
				record.name = n;

				return n;
			}

			function fetchProducts() {
				processing.value = true;
				http.get(`/api/products?fetch=all`).then((res) => {
					products.value = res.data;
					processing.value = false;
				});
			}

			function searchProducts() {
				http.get(`/api/products?s=${search.value}`).then((res) => {
					products.value = res.data;
				});
			}

			function fetchCustomers() {
				processing.value = true;
				http.get(`/api/users?role=customer&fetch=all`).then((res) => {
					customers.value = res.data;
					processing.value = false;
				});
			}

			function fetchAssignments(page = 1) {
				processing.value = true;

				http.get(
					`/api/assignments?user_id=${record.user_id}&page=${page}`
				).then((res: any) => {
					assignments.value = res.data;
					processing.value = false;
				});
			}

			function fetchUserAssignments(id: any) {
				user_id.value = id;
				processing.value = true;

				http.get(`/api/assignments?user_id=${id}`).then((res: any) => {
					assignments.value = res.data;
					processing.value = false;
				});
			}

			function createRecord() {
				http.post("/api/assignments", record)
					.then((res) => {
						if (res.data.success) {
							created.value = true;
						}
					})
					.finally(() => {
						creating.value = false;
						document.getElementById("hideNewForm")?.click();
						fetchUserAssignments(record.user_id);
					});
			}

			function updateRecord(r: any) {
				r._method = "PUT";
				r.name = formatName(r);

				http.post(`/api/assignments/${r.id}`, r)
					.then((res) => {
						if (res.data.success) {
							updated.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideEditForm")?.click();
						fetchUserAssignments(r.user_id);
					});
			}

			function deleteRecord(id: any) {
				http.post(`/api/assignments/${id}`, { _method: "DELETE" }).then(
					(res) => {
						fetchUserAssignments(user_id.value);
					}
				);
			}

			function getCosts(type: any) {
				return costs.value?.filter(
					(cost: any) => cost?.category?.slug == type
				);
			}

			function creatingNew() {
				creating.value = true;
			}

			onMounted(() => {
				fetchProducts();
				fetchCustomers();

				http.get(`/api/costs?fetch=all`).then((res: any) => {
					costs.value = res.data;
				});
			});

			return {
				headers,
				searchProducts,
				fetchProducts,
				processing,
				products,
				createRecord,
				created,
				record,
				updateRecord,
				updated,
				deleteRecord,
				search,
				customers,
				customer,
				fetchAssignments,
				fetchUserAssignments,
				product,
				costs,
				getCosts,
				states,
				setName,
				formatName,
				assignments,
				box,
				primary,
				creating,
				creatingNew,
				fD,
			};
		},
	});
